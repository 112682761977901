import React from 'react';
import { PageProps } from 'gatsby';

import { WithTranslation, Trans, withTranslation } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';
import CommonData from '../../data/common/common';
import PageMenu from '../../components/PageMenu/PageMenu';
import borrowerMenu from '../../data/page-menus/borrower-menu';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../components/Icon/Icon';

type Props = PageProps & WithTranslation;

const Borrower: React.FC<Props> = (props: Props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-11.jpg',
          alt: 'Image',
        },
        blockText: t('tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          return <li key={`${str}-${i}`}>{str}</li>;
        })}
      </ul>
    );
  };

  const mortgageServicingEmail = t(`${LocaleNameSpaceKeys.COMMON}:emails.mortgageServicing`);

  const mortgageServicingTollFreeNumber =
    CommonData.tollFreeCustomerServiceNumber.mortgageServicing;

  return (
    <Layout options={layoutOptions} className="Borrower">
      <div className="grid-container">
        <div className="row row-wrap Borrower__generic-gap">
          <section className="column column-60">
            <div className="Borrower__generic-gap">
              <p>{t('introBlock.body')}</p>
              {generateList(t('introBlock.list', { returnObjects: true }))}
            </div>

            <div className="row row-wrap Borrower__generic-gap">
              <h2>{t('troubleLoggingInBlock.heading')}</h2>
              <p>
                <Trans
                  i18nKey="troubleLoggingInBlock.body"
                  t={t}
                  values={{
                    mortgageServicingEmail,
                    tollFreeNumber: mortgageServicingTollFreeNumber,
                  }}
                >
                  <ExternalLink
                    href={`tel:${mortgageServicingTollFreeNumber}`}
                    aria-label={`${t(
                      `${LocaleNameSpaceKeys.COMMON}:tollFRee`
                    )} ${mortgageServicingTollFreeNumber}`}
                  />
                  <ExternalLink
                    href={`mailto:${mortgageServicingEmail}`}
                    aria-label={`${t(
                      `${LocaleNameSpaceKeys.COMMON}:email`
                    )} ${mortgageServicingEmail}`}
                  />
                </Trans>
              </p>
            </div>
          </section>
          <aside className="column column-33">
            <ButtonLink
              className="Borrower__login-button"
              to={process.env.GATSBY_CUSTOMER_PORTAL_URL || ''}
              linkOptions={{ isExternalLink: true }}
              iconOptions={{
                icon: IconTypes.LOCK,
              }}
              styleOptions={{
                invertColors: true,
                isInline: true,
              }}
            >
              {t('loginButtonLabel')}
            </ButtonLink>
            <PageMenu
              menu={borrowerMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.otherResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BORROWER)(Borrower);
